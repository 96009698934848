import React from 'react';
import {Link} from 'react-router-dom';
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import NqImage from '../NqImage';

import LogoutButton from '../LogoutButton';

import './Menu.css';

class Menu extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <Link className="navbar-brand" to={{pathname: '/administration'}}>
          <NqImage className="logo" src="/ncore_logo_full_color.png" alt=""/>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navBarContent" aria-controls="navBarContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"/>
        </button>
        <div className="collapse navbar-collapse" id="navBarContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to={{pathname: '/administration/benutzer'}}>Benutzer</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={{pathname: '/administration/mandanten'}}>Mandanten</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to={{pathname: '/administration/demoprojekte'}}>Demoprojekte</Link>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle" id="userIconDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <FontAwesomeIcon icon={faUser}/>
              </span>
              <div className="dropdown-menu" aria-labelledby="userIconDropdown">
                <LogoutButton className="dropdown-item"/>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Menu;
