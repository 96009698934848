import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Container } from 'react-bootstrap';
import i18n from '../i18n';

class RestorePasswordEmailSentPage extends React.Component {
  render() {
    return (
      <Container >
        <div className="py-3 text-center" data-test-id="confirmation">
          <h4>{i18n.t('email.password')}</h4>
        </div>
        <Row className="justify-content-md-center">
          <div className="col col-6" data-test-id="back-to-main">
            <Link id="back-to-main-page" to="/">{i18n.t('back-to-main-page')}</Link>
          </div>
        </Row>
      </Container >
    );
  }
}

export default RestorePasswordEmailSentPage;
