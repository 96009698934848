import React from 'react';
import './App.css';
import Login from './components/Login'
import Logout from './components/Logout'
import Administration from './components/admin/Administration.js'
import TenantSelection from './components/tenantSelection/TenantSelection'
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import RestorePasswordPage from './components/RestorePasswordPage';
import RestorePasswordEmailSentPage from './components/RestorePasswordEmailSentPage';
import PasswordConfirmationPage from './components/PasswordConfirmationPage';
import * as auth from './redux/Auth';
import SetPassword from './components/SetPassword';
import RequestDemoProject from './components/requestDemoProject/RequestDemoProject'

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/passwort-vergabe-link-versendet">
            <RestorePasswordEmailSentPage />
          </Route>
          <Route path="/passwort-vergessen" component={RestorePasswordPage}>
          </Route>
          <Route path="/passwort-bestaetigung" component={PasswordConfirmationPage}>
          </Route>
          <Route path="/login" component={Login}>
          </Route>
          <Route path="/passwort-vergabe" component={SetPassword}>
          </Route>
          <Route path="/logout" component={Logout}>
          </Route>
          <PrivateRoute path="/administration" withRole={auth.ADMIN_ROLE}>
            <Administration />
          </PrivateRoute>
          <Route path="/demozugang-anfordern">
            <RequestDemoProject />
          </Route>
          <PrivateRoute path="/">
            <TenantSelection />
          </PrivateRoute>
        </Switch>
      </Router>
    );
  }
}
