import './TenantTransactions.css'

import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Collapsible from 'react-collapsible';
import BackendServices from '../../../BackendServices';
import {Button, Col} from 'react-bootstrap';
import {TextInput, centsToEuroCents, euroCentsToCents} from './TenantForm';
import { format } from 'date-fns';

const initialValues = {note: '', amount: ''};
export default class TenantTransactions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sumLoaded: false,
      transactionsLoaded: false
    };
  }

  async componentDidMount() {
    await this.fetchTransactionSum();
    await this.fetchTransactions();
  }

  async fetchTransactionSum() {
    const transactionSumResponse =
        await BackendServices.fetchTransactionSum(this.props.tenantId);
    if (BackendServices.wasSuccessful(transactionSumResponse)) {
      const transactionSumInCents = await transactionSumResponse.json();
      this.setState({
        transactionSumInCents,
        sumLoaded: true,
        sumLoadedSuccessfully: true
      })
    } else {
      this.setState({
        sumLoaded: true,
        sumLoadedSuccessfully: false
      })
    }
  }

  async fetchTransactions() {
    const transactionsResponse =
        await BackendServices.fetchTransactions(this.props.tenantId);
    if (BackendServices.wasSuccessful(transactionsResponse)) {
      const transactions = await transactionsResponse.json();
      this.setState({
        transactions,
        transactionsLoaded: true,
        transactionsLoadedSuccessfully: true
      });
    } else {
      this.setState({
        transactionsLoaded: true,
        transactionsLoadedSuccessfully: false
      })
    }
  }

  render() {
    const validationSchema = Yup.object().shape({
      note: Yup.string()
        .required('Bitte geben sie eine Notiz ein'),
      amount: Yup.string()
        .required('Bitte geben sie einen gültigen EUR,CT-Betrag ein')
        .matches(/^[0-9]{1,6}(?:,[0-9]{2})?$/, 'Bitte geben sie einen gültigen EUR,CT-Betrag ein'),
    });
    return (
      <div className="container overflow-auto mt-12 mb-12">
        <div className="row mt-3 mb-3">
          <h2>Transaktionen</h2>
        </div>

        <Formik
          initialValues = {initialValues}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            this.createTransaction(values, setSubmitting, resetForm);
          }}
          validationSchema={validationSchema}>
          {(
            {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            }) => {
            const formikPropertiesForChildInputs = {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
            };
            return (
              <form onSubmit={handleSubmit}>
                <TextInput {...formikPropertiesForChildInputs} fieldName="note" displayName="Notiz"
                  placeholder="Notiz"/>
                <TextInput {...formikPropertiesForChildInputs} fieldName="amount" displayName="Betrag"
                  placeholder="123,45"/>
                <div className="form-group row justify-content-center">
                  <Col sm={1}>
                    <Button type="submit" disabled={isSubmitting} variant={'primary'}>Erstellen</Button>
                  </Col>
                </div>
              </form>
            )
          }}
        </Formik>

        {!this.state.sumLoaded &&
        <div>Daten laden...</div>}
        {this.state.sumLoaded &&
        !this.state.sumLoadedSuccessfully &&
        <div id="sum-error">Laden der Daten fehlgeschlagen</div>}
        {this.state.sumLoaded &&
        this.state.sumLoadedSuccessfully &&
        this.showTransactionSum()
        }

        {!this.state.transactionsLoaded &&
        this.state.transactionsLoadedSuccessfully &&
        <div>Daten laden...</div>}
        {this.state.transactionsLoaded &&
        !this.state.transactionsLoadedSuccessfully &&
        <div id="transactions-error">Laden der Daten fehlgeschlagen</div>}
        {this.state.transactionsLoaded &&
         this.state.transactionsLoadedSuccessfully &&
        this.showTransactionDetails()}
      </div>
    );
  }

  async createTransaction(values, isSubmitting, resetForm) {
    const note = values.note;
    const amountInCents = euroCentsToCents(values.amount);
    const transactionToStore = {note, amountInCents}
    const response = await BackendServices.createTransaction(this.props.tenantId, transactionToStore);
    if (BackendServices.wasSuccessful(response)) {
      this.componentDidMount();
      resetForm(initialValues);
    }
    isSubmitting(false);
  }

  showTransactionSum() {
    return <div>
      <div className="row">
        <label htmlFor={'transactionSumInCents'} className="col-sm-2 col-form-label">Kontostand (in EUR)</label>
        <Col sm={5}>
          <input id={'transactionSumInCents'}
            disabled={true}
            type='text'
            name={'transactionSumInCents'}
            value={centsToEuroCents(this.state.transactionSumInCents)}/>
        </Col>
      </div>
    </div>
  }

  showTransactionDetails() {
    return <div>
      <div>
        <Collapsible trigger="Details" class = "mt-12 mb-12">
          <div>
            <table className="table table-bordered">
              <thead className={'thead-light'}>
                <tr>
                  <th>Datum</th>
                  <th>Erstellt von</th>
                  <th>Typ</th>
                  <th>Summe (in EUR)</th>
                  <th>Notiz</th>
                </tr>
              </thead>
              <tbody>
                {this.state.transactions.map((transaction, index) => {
                  const created = format(Date.parse(transaction.created), 'dd.MM.yyyy HH:mm:ss');
                  return(
                    <tr key={index} id={transaction.id}>
                      <td>{created}</td>
                      <td>{transaction.creatorEmail}</td>
                      <td>{this.mapTransactionText(transaction)}</td>
                      <td>{centsToEuroCents(transaction.amountInCents)}</td>
                      <td>{transaction.note}</td>
                    </tr>
                  )})}
              </tbody>
            </table>
          </div>
        </Collapsible>
      </div>
    </div>
  }

  mapTransactionText(transaction) {
    if (transaction.eventType === 'WITHDRAWAL') {
      return 'Abbuchung';
    } else if (transaction.eventType === 'DEPOSIT') {
      return 'Einzahlung';
    } else {
      return 'Typ unbekannt'
    }
  }
}
