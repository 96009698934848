import React from 'react'
import PropTypes from 'prop-types'
import Modal from './Modal'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import i18n from '../i18n';

export default class ErrorModal extends React.Component {
  static props = {
    onClose: PropTypes.func
  };

  renderErrorMsg() {
    if (this.props.errorMsg) {
      return i18n.t(this.props.errorMsg);
    }
    if (this.props.errorMsgHtml) {
      return <span dangerouslySetInnerHTML={{ __html: i18n.t(this.props.errorMsgHtml) }} />
    }
    return i18n.t('error.default');
  }

  render() {
    i18n.changeLanguage(this.props.lng);
    return (
      <Modal large show={this.props.show} onClose={this.props.onClose} titel="Fehler">
        <div className="modal-header">
          <span className="modal-title">{i18n.t('error.text')}</span>
          <FontAwesomeIcon icon={faExclamationCircle} size="2x" />
        </div>
        <div className="modal-body">
          <div className="list-group mt-2">
            <p>
              {this.renderErrorMsg()}
            </p>
          </div>
        </div>
        <div className="modal-footer">
          <Button variant={'primary'} onClick={this.props.onClose}>
            OK
          </Button>
        </div>
      </Modal>
    )
  }
}
