import React from 'react';
import BackendServices from '../BackendServices';
import {connect} from 'react-redux';
import * as auth from '../redux/Auth'
import {Button} from 'react-bootstrap';

class LogoutButton extends React.Component {
  constructor(props) {
    super(props);
    this.logoutHandler = async () => {
      if (await BackendServices.logout()) {
        props.setLogout();
      }
    };
  }

  render() {
    const className = this.props.className || '';
    return (
      <Button className={`nq-btn nq-logout ${className}`} onClick={this.logoutHandler}>
        Log out
      </Button>
    );
  }
}

export default connect(
  undefined,
  (dispatch) => ({
    setLogout: () => dispatch(auth.actions.setLogout())
  })
)(LogoutButton);
