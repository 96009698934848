export const ADMIN_ROLE = 'ADMIN';
export const USER_ROLE = 'USER';

const INITIAL_STATE =
  {
    authenticated: false,
    userRoles: []
  };

export const actions = {
  setLogin: (userRoles) => ({
    type: 'LOGIN',
    userRoles: userRoles
  }),
  setLogout: () => ({
    type: 'LOGOUT'
  }),
};

export const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'LOGIN':
      return {
        ...state,
        authenticated: true,
        userRoles: action.userRoles
      };

    case 'LOGOUT':
      return {
        ...state,
        authenticated: false
      };

    default:
      return state;

  }
};

export default reducer;
