import React from 'react';
import {extractContextPathPrefix} from '../util'

export default class NqImage extends React.Component {
  render() {
    const contextPathPrefix = extractContextPathPrefix();
    const src = contextPathPrefix && contextPathPrefix.length > 0 ?
      '/' + contextPathPrefix + this.props.src : this.props.src;
    return (
      <img
        className={this.props.className}
        alt={this.props.alt}
        src={src}/>
    );
  }
}
