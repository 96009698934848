import './Login.css';
import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { validateUsingCustomYupSchema } from '../util';
import queryString from 'query-string';
import BackendServices from '../BackendServices';
import ErrorModal from './ErrorModal';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import i18n from '../i18n';

export default class SetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      handleInvalid: false,
      unexpectedError: false,
      passwordSetExecutedSuccessfully: false
    };
  }

  componentDidMount() {
    const handle = this.extractHandle(this.props.location.search);
    this.setState({ handle: handle });

    if (!handle) {
      this.setHandleInvalid();
    } else {
      this.validateSetPasswordHandle(handle);
    }
  }

  setHandleInvalid() {
    this.setState({ handleInvalid: true });
  }

  async validateSetPasswordHandle(handle) {
    const response = await BackendServices.validateSetPasswordHandle(handle);
    if (!BackendServices.wasSuccessful(response)) {
      if (response.status !== 412) {
        this.setUnexpectedError();
      } else {
        this.setHandleInvalid();
      }
    }
  }

  setUnexpectedError() {
    this.setState({ unexpectedError: true });
  }

  extractHandle(pathParams) {
    let handle;
    if (pathParams) {
      const params = queryString.parse(this.props.location.search);
      handle = params.handle;
    }

    return handle;
  }

  getValidationSchema(values) {
    const passwordCriteriaMsg = i18n.t('password.criteria.match');
    return Yup.object().shape({
      password: Yup.string()
        .required(i18n.t('password.input.password'))
        .min(6, i18n.t('password.input.min-6-chars'))
        .oneOf([values.password]).matches(/[a-z]/, passwordCriteriaMsg)
        .oneOf([values.password]).matches(/[0-9]/, passwordCriteriaMsg)
        .oneOf([values.password]).matches(/[A-Z]/, passwordCriteriaMsg),
      passwordConfirmation: Yup.string()
        .required(i18n.t('password.confirm.long'))
        .oneOf([values.password], i18n.t('password.no-match'))
    })
  }

  async setPassword(values, setSubmitting) {
    try {
      const response = await BackendServices.setPassword(values.password, this.state.handle);
      if (!BackendServices.wasSuccessful(response)) {
        this.setUnexpectedError();
      } else {
        this.setState({ passwordSetExecutedSuccessfully: true });
      }
    } catch (e) {
      this.setUnexpectedError();
    }
    setSubmitting(false);
  }

  handleCloseErrorMsg = () => {
    this.setState({ unexpectedError: false });
  };

  render() {
    const lng = queryString.parse(this.props.location.search).lng;
    i18n.changeLanguage(lng);

    const registrationMarkup = <>
      <h2 id="password-assignment">{i18n.t('password.assignment')}</h2>

      <p>
        {i18n.t('password.assign.long')}
        <br />
        {i18n.t('password.criteria.text')}
      </p>
      <ul>
        <li>{i18n.t('password.criteria.length')}</li>
        <li>{i18n.t('password.criteria.uppercase')}</li>
        <li>{i18n.t('password.criteria.lowercase')}</li>
        <li>{i18n.t('password.criteria.number')}</li>
      </ul>
      <Formik
        initialValues={{ password: '', passwordConfirmation: '' }}
        onSubmit={(values, { setSubmitting }) => {
          this.setPassword(values, setSubmitting);
        }}
        validate={validateUsingCustomYupSchema(this.getValidationSchema)}
      >
        {props => {
          const {
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          } = props;
          return (

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="password">{i18n.t('password.text')}</label>
                <input type="password" id="password"
                  className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''}`}
                  onChange={handleChange} onBlur={handleBlur} />
                <ErrorMessage component="div" name="password" className="invalid-feedback" />
              </div>
              <div className="form-group">
                <label htmlFor="passwordConfirmation">{i18n.t('password.confirm.short')}</label>
                <input type="password" id="passwordConfirmation"
                  className={`form-control ${touched.passwordConfirmation && errors.passwordConfirmation ? 'is-invalid' : ''}`}
                  onChange={handleChange} onBlur={handleBlur} />
                <ErrorMessage component="div" name="passwordConfirmation" className="invalid-feedback" />
              </div>

              <Button block={true} type="submit" disabled={isSubmitting}>
                {i18n.t('password.assign.short')}
              </Button>
            </form>
          )
        }}
      </Formik>
    </>;

    const invalidHandleMarkup = <>
      <div className="alert alert-danger" role="alert">
        {i18n.t('link.invalid')}
      </div>
    </>;

    return (
      <React.Fragment>
        {this.state.passwordSetExecutedSuccessfully &&
          <Redirect to="/passwort-bestaetigung" />
        }
        <ErrorModal show={this.state.unexpectedError} onClose={this.handleCloseErrorMsg} />
        <div className="container h-100">

          <div className="col-md-6 col-lg-6 mx-auto">
            {this.state.handleInvalid ? invalidHandleMarkup : registrationMarkup}
          </div>
        </div>
      </React.Fragment>
    )
  }
}
