import React from 'react';
import BackendServices from '../../../BackendServices';
import { Col, Container, ListGroup, Row } from 'react-bootstrap'
import DemoProjectDetailView from './DemoProjectDetailView';
import { findMatchingErrorMessage } from '../../../util';
import ErrorModal from '../../ErrorModal';
import i18n from '../../../i18n';

export default class DemoProjectOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoProjects: [],
    };
    this.loadDemoProjectList = this.loadDemoProjectList.bind(this);
  }

  async componentDidMount() {
    await this.loadDemoProjectList();
  }

  async loadDemoProjectList() {
    const response = await BackendServices.fetchAllDemoProjects();
    if (BackendServices.wasSuccessful(response)) {
      this.setState({ demoProjects: await response.json() })
    } else {
      this.setState({ errorMsg: i18n.t(await findMatchingErrorMessage(response)) });
    }
  }

  render() {
    return (
      <Container>
        <Row className="my-3">
          <h2>Demoprojekte</h2>
        </Row>
        <ErrorModal show={!!this.state.errorMsg} errorMsg={this.state.errorMsg} onClose={() => this.setState({ errorMsg: null })} />
        <Row>
          <Col style={{ height: '85vh', overflowY: 'auto' }} md={4}>
            <ListGroup>
              {this.state.demoProjects.map((project) => {
                return (
                  <ListGroup.Item key={project.id} action
                    onClick={() => this.setState({ selectedProject: project })}>
                    {project.email}
                  </ListGroup.Item>
                )
              })}
            </ListGroup>
          </Col>
          <Col md={8}>
            <DemoProjectDetailView
              demoProject={this.state.selectedProject}
              masterListReloadCallback={(selectedProject) => {
                this.setState({ selectedProject: selectedProject });
                this.loadDemoProjectList();
              }} />
          </Col>
        </Row>
      </Container>
    );
  }
}
