import React from 'react';
import {Row, Table} from 'react-bootstrap';
import StatusRow from './StatusRow';
import KeyValueTableRow from './KeyValueTableRow';
import DeleteLocallyComponent from './DeleteLocallyComponent';
import DeleteInNqWebComponent from './DeleteInNqWebComponent';
import BackendServices from '../../../BackendServices';
import CreateDemoProjectComponent from './CreateDemoProjectComponent';

export default class DemoProjectDetailView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoProject: props.demoProject,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (typeof this.state.demoProject !== 'undefined' && this.state.demoProject != null && nextProps.demoProject != null ){
      if (this.state.demoProject.email !== nextProps.demoProject.email) {
        clearInterval(this.pollingInterval);
      }
    }
    this.setState({ demoProject: nextProps.demoProject });
  }

  render() {
    if (this.state.demoProject) {
      return (
        <div>
          <Table bordered>
            <tbody>
              <KeyValueTableRow fieldName='Angefordert'
                value={this.getFormattedLocalizedDateTimeString(this.state.demoProject.createdDate)}/>
              <KeyValueTableRow fieldName='Vorname' value={this.state.demoProject.firstName}/>
              <KeyValueTableRow fieldName='Nachname' value={this.state.demoProject.lastName}/>
              <KeyValueTableRow fieldName='E-Mail' value={this.state.demoProject.email}/>
              <KeyValueTableRow fieldName='Telefon' value={this.state.demoProject.phoneNumber}/>
              <KeyValueTableRow fieldName='Firma' value={this.state.demoProject.company}/>
              <StatusRow value={this.state.demoProject.state}/>
              <KeyValueTableRow fieldName='Letzte Statusaktualisierung'
                value={this.getFormattedLocalizedDateTimeString(this.state.demoProject.lastStateChange)}/>
            </tbody>
          </Table>
          <Row className={'px-3'}>
            <CreateDemoProjectComponent
              demoProject={this.state.demoProject} callback={(updatedDemoProject) =>{
                this.props.masterListReloadCallback(updatedDemoProject);
                this.enablePollingForUpdates(updatedDemoProject);
              }}
            />
          </Row>
          <Row className={'p-3'}>
            <DeleteInNqWebComponent
              demoProject={this.state.demoProject} callback={(updatedDemoProject) =>{
                this.props.masterListReloadCallback(updatedDemoProject);
                this.enablePollingForUpdates(updatedDemoProject);
              }}
            />
          </Row>
          <Row className={'px-3'}>
            <DeleteLocallyComponent
              demoProject={this.state.demoProject}
              masterListReloadCallback={this.props.masterListReloadCallback}
            />
          </Row>
        </div>);
    } else {
      return null;
    }
  }

  enablePollingForUpdates(updatedDemoProject) {
    if (typeof this.pollingInterval === 'undefined') {
      this.pollingInterval = setInterval(async () => {
        const response = await BackendServices.fetchDemoProject(updatedDemoProject.email);
        if (response.ok) {
          this.setState({demoProject: await response.json()});
        } else {
          if (response.status === 404) {
            clearInterval(this.pollingInterval);
            this.setState({demoProject: null});
          }
        }

      }, 5000);    
    }
  }

  getFormattedLocalizedDateTimeString(string) {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'};
    let date = new Date(string);
    date.setMinutes(date.getMinutes() - new Date().getTimezoneOffset())
    return date.toLocaleDateString('de-DE', options);
  }
}