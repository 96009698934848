import React, {useEffect, useState} from 'react';
import {Redirect, Route} from 'react-router-dom';
import BackendServices from '../BackendServices';
import {connect} from 'react-redux';
import * as auth from '../redux/Auth';
import ErrorModal from './ErrorModal';

const checkUserHasRole = function (withRole, userRoles) {
  return !withRole || userRoles.indexOf(withRole) >= 0;
};

// heavily inspired by https://reacttraining.com/react-router/web/example/auth-workflow
function PrivateRoute({children, withRole, isAuthenticated, userRoles, setLogin, ...rest}) {
  const [data, setData] = useState({
    authenticated: false,
    authenticationExecuted: false,
    hasRole: false,
    missingRoleAcknowledged: false
  });

  useEffect(() => {
    const checkLogin = async () => {
      if (isAuthenticated) {
        setData({
          authenticated: true,
          authenticationExecuted: true,
          hasRole: checkUserHasRole(withRole, userRoles)
        });
      } else {
        // trying to refresh login
        const result = await BackendServices.refreshTokenWithRoles();
        if (result.ok) {
          setLogin(result.userRoles);
        }
        setData({
          authenticated: result.ok,
          authenticationExecuted: true,
          hasRole: checkUserHasRole(withRole, result.userRoles)
        });
      }
    };

    checkLogin();
  }, [isAuthenticated, withRole, userRoles, setLogin]);

  const handleCloseNoRoleMessage = () => {
    setData({
      ...data,
      missingRoleAcknowledged: true
    });
  };

  const render = ({location}) => {
    if (!data.authenticationExecuted) {
      // waiting for authentication to finish
      return null;
    }
    if (!data.authenticated) {
      // user has to authenticate
      return <Redirect to={{
        pathname: '/login',
        state: {from: location}
      }}/>;
    }
    if (data.missingRoleAcknowledged) {
      // after acknowledging the error for 'missing role', redirect to start page
      return <Redirect to="/"/>;
    }
    if (!data.hasRole) {
      // user does not have the correct role for this page
      const errorMessage = 'Sie haben keine Berechtigung diese Seite zu sehen.';
      return <ErrorModal show errorMsg={errorMessage} onClose={handleCloseNoRoleMessage}/>;
    }

    // everything OK, render page
    return children;
  };

  return <Route {...rest} render={render}/>;
}

export default connect(
  state => ({
    isAuthenticated: state.authenticated,
    userRoles: state.userRoles
  }),
  dispatch => ({
    setLogin: (userRoles) => dispatch(auth.actions.setLogin(userRoles))
  })
)(PrivateRoute)
