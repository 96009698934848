import Log from 'js-logger';

export default class BackendServices {
  static _refreshTokenTimeoutId;
  static _frontEndConfig;

  static contextPathPrefix = '';

  // ui might be deployed on test environments which are distinguished by using a path prefix
  static _getPath(apiPath) {
    if (this.contextPathPrefix && this.contextPathPrefix.length > 0) {
      return '/' + this.contextPathPrefix + apiPath;
    } else {
      return apiPath;
    }
  }

  static async _nqFetch(path, config) {
    if (config) {
      return fetch(this._getPath(path), config);
    } else {
      return fetch(this._getPath(path));
    }
  }

  static async fetchTenants() {
    const response = await this._nqFetch('/api/me/tenants', {
      method: 'GET',
      credentials: 'same-origin'
    });

    if (this.wasSuccessful(response)) {
      return response.json();
    } else {
      return [];
    }
  }

  static async fetchAllTenants() {
    const response = await this._nqFetch('/api/tenants', {
      method: 'GET',
      credentials: 'same-origin'
    });

    if (this.wasSuccessful(response)) {
      return response.json();
    } else {
      return [];
    }
  }

  static async createTenant(tenant) {
    const body = JSON.stringify(tenant);
    return this._nqFetch('/api/tenants', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: body
    });
  }

  static async fetchCustomerGroup() {
    const response = await this._nqFetch('/api/customerGroups', {
      method: 'GET',
      credentials: 'same-origin'
    });

    if (this.wasSuccessful(response)) {
      return response.json();
    } else {
      return [];
    }
  }

  static async fetchCustomerSalesStatus() {
    const response = await this._nqFetch('/api/salesStatuses', {
      method: 'GET',
      credentials: 'same-origin'
    });

    if (this.wasSuccessful(response)) {
      return response.json();
    } else {
      return [];
    }
  }

  static async fetchExistingTenant(tenantId) {
    const response = await this._nqFetch('/api/tenants/' + tenantId, {
      method: 'GET',
      credentials: 'same-origin'
    });
    if (!BackendServices.wasSuccessful(response)) {
      Log.error('could not fetch tenant', response);
      throw new Error('could not fetch tenant with ID ' + tenantId);
    }
    return response.json();
  }

  static wasSuccessful(response) {
    return response.ok;
  }

  static async _login(username, password) {
    const formValues = {
      username: username,
      password: password
    };
    const body = this._createFormEncoded(formValues);

    const response = await this._nqFetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: body
    });

    const loginResponse = await response.json();
    if (this.wasSuccessful(response)) {
      this._registerRefreshToken(loginResponse.expires_in);
    }

    response.extractedBody = loginResponse;
    return response;
  }

  static async logout() {
    try {
      const response = await this._nqFetch('/api/logout');
      if (this.wasSuccessful(response)) {
        this._deregisterRefreshToken();
        return true;
      }
    } catch (e) {
      Log.error('log out failed', e);
    }
    return false;
  }

  static _registerRefreshToken(timeoutSeconds) {
    this._refreshTokenTimeoutId = setTimeout(() => {
      this._refreshToken();
    }, timeoutSeconds * 0.8 * 1000);    // after 80% of the expiry trigger a refresh
  }

  static _deregisterRefreshToken() {
    if (!this._refreshTokenTimeoutId) {
      return;
    }
    clearTimeout(this._refreshTokenTimeoutId);
  }

  static async _refreshToken() {
    try {
      const body = this._createFormEncoded({ grant_type: 'refresh_token' });

      const response = await this._nqFetch('/api/refresh_token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'same-origin',
        body: body
      });

      if (!this.wasSuccessful(response)) {
        Log.error('error while refreshing token');
      }
      const refreshResponse = await response.json();
      this._registerRefreshToken(refreshResponse.expires_in ? refreshResponse.expires_in : 200);

      return response;
    } catch (e) {
      Log.error('error while refreshing token: ' + e);
      return false;
    }
  }

  static async _fetchUserRoles() {
    const response = await this._nqFetch('/api/me/roles', {
      method: 'GET',
      credentials: 'same-origin'
    });

    if (this.wasSuccessful(response)) {
      const roleResponse = await response.json();
      return roleResponse.map((backendRole) => backendRole.roleName);
    } else {
      return [];
    }
  }

  static async loginWithRoles(username, password) {
    return this._executeWithRoles(() => this._login(username, password));
  }

  static async refreshTokenWithRoles() {
    return this._executeWithRoles(() => this._refreshToken());
  }

  // executes the given function (for login  or refresh) plus fetches user's role
  static async _executeWithRoles(firstCall) {
    const refreshResponse = await firstCall();
    if (this.wasSuccessful(refreshResponse)) {
      await this._fetchFrontEndConfig(); // get config right after login / refresh
      const userRolesResponse = await this._fetchUserRoles();
      return {
        ok: true,
        userRoles: userRolesResponse
      };
    }
    return {
      ok: false,
      backendResponse: refreshResponse,
      userRoles: []
    }
  }

  static _createFormEncoded(formValues) {
    return Object.keys(formValues).map((key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(formValues[key]);
    }).join('&');
  }

  static async validateSetPasswordHandle(handle) {
    return this._nqFetch('/api/invitation/checkValidity?handle=' + encodeURIComponent(handle), {
      method: 'GET'
    });
  }

  static async setPassword(password, handle) {
    const body = JSON.stringify({ password: password, invitationPayload: handle });
    return this._nqFetch('/api/users/password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: body
    });
  }

  static async requestNewPassword(email, reCaptchaToken, language) {
    const body = JSON.stringify({ email, language });
    return this._nqFetch('/api/users/new-password-request', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'recaptcha-token': reCaptchaToken },
      body: body
    });
  }

  static async fetchAllUsers() {
    return this._nqFetch('/api/users', {
      method: 'GET',
      credentials: 'same-origin'
    });
  }

  static async addRolesToUser(userEmail, roles) {
    const body = JSON.stringify(roles);
    return this._nqFetch('/api/users/' + encodeURIComponent(userEmail) + '/roles', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: body
    });
  }

  static async lockUser(userEmail) {
    return this._nqFetch('/api/users/' + userEmail + '/lock', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });
  }

  static async unlockUser(userEmail) {
    return this._nqFetch('/api/users/' + userEmail + '/unlock', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    });
  }

  static async removeRolesFromUser(userEmail, roles) {
    const body = JSON.stringify(roles);
    return this._nqFetch('/api/users/' + encodeURIComponent(userEmail) + '/roles', {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: body
    });
  }

  static async fetchTenantBillingData(tenantId, month, year) {
    return this._nqFetch('/api/tenants/' + tenantId + '/billings/year/' + year + '/month/' + month, {
      method: 'GET',
      credentials: 'same-origin'
    });
  }

  static async sendBillingPerMail(tenantId, month, year) {
    return this._nqFetch('/api/tenants/' + tenantId + '/billings/year/' + year + '/month/' + month + '/email', {
      method: 'POST',
      credentials: 'same-origin'
    });
  }

  static async createBilling(tenantId, month, year) {
    let body = JSON.stringify({ year: year, month: month });
    return this._nqFetch('/api/tenants/' + tenantId + '/billings', {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: body
    });
  }
  static getDownloadURL(tenantId) {
    return this._getPath('/api/tenants/' + tenantId + '/billings/');
  }

  static getTenantPathPrefix() {
    return this._frontEndConfig.tenantPathPrefix;
  }

  static async getReCaptchaSiteKey() {
    await this._fetchFrontEndConfig();
    return this._frontEndConfig.reCaptchaSiteKey;
  }

  static async _fetchFrontEndConfig() {
    if (this._frontEndConfig) {
      Log.debug('front-end config has already been fetched');
      return;
    }
    const response = await this._nqFetch(
      '/api/config/front-end',
      {
        method: 'GET',
        credentials: 'same-origin'
      }
    );
    if (!this.wasSuccessful(response)) {
      const message = 'could not fetch front-end config';
      Log.error(message, response);
      throw new Error(message);
    }
    this._frontEndConfig = await response.json();
  }

  static async activateUserOnTenants(userEmail) {
    await this._nqFetch('/api/users/' + userEmail + '/activateOnTenants', {
      method: 'POST',
      credentials: 'same-origin'
    });
  }

  static async fetchAllDemoProjects() {
    return this._nqFetch('/api/demoprojects', {
      method: 'GET',
      credentials: 'same-origin'
    });
  }

  static async requestNewDemoProject(values, reCaptchaToken) {
    return this._nqFetch('/api/demoprojects', {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json', 'recaptcha-token': reCaptchaToken },
    });
  }

  static async deleteDemoProjectMetadata(email) {
    return this._nqFetch(`/api/demoprojects/${email}`, {
      method: 'DELETE',
      credentials: 'same-origin',
    });
  }

  static async deleteDemoProjectContents(email) {
    return this._nqFetch(`/api/demoprojects/${email}/contents`, {
      method: 'DELETE',
      credentials: 'same-origin',
    });
  }

  static async fetchDemoProject(email) {
    return this._nqFetch(`/api/demoprojects/${email}`, {
      method: 'GET',
      credentials: 'same-origin'
    });
  }

  static async changeState(email, targetState) {
    return this._nqFetch(`/api/demoprojects/${email}/actions/state-changes`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ state: targetState }),
      credentials: 'same-origin'
    });
  }

  static async fetchTransactionSum(tenantId) {
    return this._nqFetch(`api/tenants/${tenantId}/balance`, {
      method: 'GET',
      credentials: 'same-origin'
    });
  }

  static async fetchTransactions(tenantId) {
    return this._nqFetch(`api/tenants/${tenantId}/transactions`, {
      method: 'GET',
      credentials: 'same-origin'
    });
  }

  static async createTransaction(tenantId, transaction) {
    const body = JSON.stringify(transaction);
    return this._nqFetch(`api/tenants/${tenantId}/transactions`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: body
    })
  }

  static async setSumReceivedInCents(tenantId, year, month, sumReceivedInCents) {
    return this._nqFetch(`/api/tenants/${tenantId}/billings/year/${year}/month/${month}/sumReceived`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: sumReceivedInCents,
      credentials: 'same-origin'
    })
  }
}
