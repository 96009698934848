import React from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Log from 'js-logger';
import BackendServices from '../../../BackendServices';
import { findMatchingErrorMessage } from '../../../util';
import ErrorModal from '../../ErrorModal';
import { Button, Col, Container } from 'react-bootstrap';
import i18n from '../../../i18n';

export class TextInput extends React.Component {
  render() {
    const {
      touched,
      errors,
      handleChange,
      handleBlur,
      values,
      fieldName,
      displayName = fieldName,
      placeholder = displayName,
      type = 'text',
      disabled = false,
      sm = 5,
    } = this.props;
    return (
      <div>
        <div className="form-group row">
          <label htmlFor={fieldName} className="col-sm-2 col-form-label">{displayName}</label>
          <Col sm={sm}>
            <input id={fieldName}
              disabled={disabled}
              type={type}
              name={fieldName}
              value={values[fieldName]}
              className={`form-control ${touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''}`}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={placeholder} />
            <ErrorMessage component="div" name={fieldName} className="invalid-feedback" />
          </Col>
        </div>
      </div>
    );
  }
}

class Select extends React.Component {

  _getKeysSortedByValues(map) {
    const valueComparator = (k1, k2) => {
      const v1 = map[k1];
      const v2 = map[k2];
      if (v1 === v2) {
        return 0;
      }
      return v1 < v2 ? -1 : +1;
    };
    return Object.keys(map).sort(valueComparator);
  }

  render() {
    const {
      touched,
      errors,
      handleChange,
      handleBlur,
      values,
      fieldName,
      displayName = fieldName,
      keyValueMap = {},
      firstOption = null,
    } = this.props;
    const keys = this._getKeysSortedByValues(keyValueMap);
    const options = keys.map((key) => <option key={key} value={key}>{keyValueMap[key]}</option>);
    return (
      <div className="form-group row">
        <label htmlFor={fieldName} className="col-sm-2 col-form-label">{displayName}</label>
        <Col sm={5}>
          <select id={fieldName}
            name={fieldName}
            value={values[fieldName]}
            className={`form-control ${touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''}`}
            onChange={handleChange}
            onBlur={handleBlur}>
            {firstOption && <option key="" value="">{firstOption}</option>}
            {options}
          </select>
          <ErrorMessage component="div" name={fieldName} className="invalid-feedback" />
        </Col>
      </div>
    );
  }
}

class TextArea extends React.Component {
  render() {
    const {
      touched,
      errors,
      handleChange,
      handleBlur,
      values,
      rows = 5,
      fieldName,
      displayName = fieldName,
      placeholder = displayName,
    } = this.props;
    return (
      <div className="form-group row">
        <label htmlFor={fieldName} className="col-sm-2 col-form-label">{displayName}</label>
        <Col sm={5}>
          <textarea rows={rows}
            id={fieldName}
            name={fieldName}
            value={values[fieldName]}
            className={`form-control ${touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''}`}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder} />
          <ErrorMessage component="div" name={fieldName} className="invalid-feedback" />
        </Col>
      </div>
    );
  }
}

const comma = ',';

export const euroCentsToCents = (baseFee) => {
  if (!baseFee) {
    return null;
  }
  let baseFeeInCentsStr;
  if (baseFee.indexOf(comma) === -1) {
    baseFeeInCentsStr = baseFee + '00';
  } else {
    baseFeeInCentsStr = baseFee.replace(new RegExp(comma, 'i'), '');
  }
  return +baseFeeInCentsStr;
};

export const centsToEuroCents = (baseFeeInCents) => {
  if (!baseFeeInCents) {
    return '';
  }
  let isNegative = baseFeeInCents < 0;
  baseFeeInCents = Math.abs(baseFeeInCents)
  const euros = Math.floor(baseFeeInCents / 100);
  const cents = baseFeeInCents % 100;
  return (isNegative ? '-' : '') + euros + comma + (cents < 10 ? '0' : '') + cents;
};

export default class TenantForm extends React.Component {
  errorMessages = {
    TENANT_ID_NOT_UNIQUE: 'Die Mandanten ID muss eindeutig sein.',
    SYSTEM_PATH_NOT_UNIQUE: 'Der Systempfad muss eindeutig sein.',
    TITLE_NOT_UNIQUE: 'Der Titel muss eindeutig sein.',
    CANNOT_ACTIVATE_TENANT: 'Der Mandant konnte nicht aktiviert werden, da kein Administrator gesetzt ist'
  };

  constructor(props) {
    super(props);
    this.state = {
      customerGroupValues: [],
      salesStatusValues: [],
      redirect: false,
      errorMsg: null,
      existingTenant: null
    };
  }

  componentDidMount() {
    this.fetchCustomerGroups();
    this.fetchCustomerSalesStatus();
    this.fetchExistingTenant();
  }

  async fetchCustomerGroups() {
    const customerGroups = await BackendServices.fetchCustomerGroup();
    this.setState({ customerGroupValues: customerGroups });
  }

  async fetchCustomerSalesStatus() {
    const customerSalesStatus = await BackendServices.fetchCustomerSalesStatus();
    this.setState({ salesStatusValues: customerSalesStatus });
  }

  async fetchExistingTenant() {
    if (!this.props.tenantId) {
      return;
    }
    const tenantDto = await BackendServices.fetchExistingTenant(this.props.tenantId);
    this._removeEmptyFields(tenantDto);
    const baseFee = centsToEuroCents(tenantDto.baseFeeInCents);
    delete tenantDto.baseFeeInCents;
    if (baseFee) {
      tenantDto.baseFee = baseFee;
    }
    tenantDto.lastSalesStatusChangeDate = new Date(tenantDto.lastSalesStatusChangeDate);
    this.setState({ existingTenant: tenantDto });
    if (this.props.onBillingEmail) {
      this.props.onBillingEmail(tenantDto.billingEmail)
    }
  }

  async createTenant(tenant, isSubmitting) {
    const tenantToStore = { ...tenant };
    this._removeEmptyFields(tenantToStore);
    this._calculateBaseFeeInCents(tenantToStore);
    const response = await BackendServices.createTenant(tenantToStore);
    if (BackendServices.wasSuccessful(response)) {
      this.setState({ saveSuccessful: true });
    } else {
      this.setState({
        ...this.state,
        saveSuccessful: false,
        errorMsg: i18n.t(await findMatchingErrorMessage(response, this.errorMessages))
      });
    }
    isSubmitting(false);
  }

  // formik/input fields give us '' - which would result in emtpy string in backend (but it should rather be null)
  _removeEmptyFields(data) {
    Log.debug('before removing empty fields', data);
    Object.keys(data).forEach((key) => {
      if (data[key] == null || data[key] === '') {
        delete data[key];
      }
    });
    Log.debug('after removing empty fields', data);
  }

  _calculateBaseFeeInCents(tenant) {
    const baseFeeInCents = euroCentsToCents(tenant.baseFee);
    delete tenant.baseFee;
    if (baseFeeInCents) {
      tenant.baseFeeInCents = baseFeeInCents;
    }
  }

  handleCloseErrorMsg = () => {
    this.setState({ errorMsg: null });
  };

  requestNewPassword(values) {
    BackendServices.requestNewPassword(values.administratorEmail);
  }

  render() {
    if (this.state.saveSuccessful) {
      return <Redirect to='/administration/mandanten' />;
    }
    const validationSchema = Yup.object().shape({
      title: Yup.string()
        .required('Bitte geben Sie einen Namen für den Mandanten ein')
        .max(30, 'Der Name darf höchstens 30 Zeichen lang sein'),
      tenantId: Yup.string()
        .required('Bitte geben Sie eine Mandanten-ID für den Mandanten ein')
        .matches(
          /^[a-zA-Z0-9-]*$/,
          'Bitte geben Sie eine gültige Mandanten-ID ein. Erlaubt sind Groß- und Kleinbuchstaben, ' +
          'sowie Ziffern und das "-" Zeichen'
        )
        .max(30, 'Die Mandanten-ID darf höchstens 30 Zeichen lang sein'),
      systemPath: Yup.string()
        .required('Bitte geben Sie den Systempfad ein')
        .max(20, 'Der Systempfad darf höchstens 20 Zeichen lang sein')
        .matches(
          /^[a-zA-Z0-9-]{1,20}$/,
          'Bitte geben Sie eine gültige Systempfad für den Mandanten ein. Erlaubt sind Groß- und ' +
          'Kleinbuchstaben, sowie Ziffern und das "-" Zeichen'
        ),
      administratorEmail: Yup.string()
        .email('Bitte geben Sie eine gültige Email ein'),
      salesStatus: Yup.string()
        .required('Bitte wählen Sie einen Status aus'),
      baseFee: Yup.string()
        .matches(/^[0-9]{1,6}(?:,[0-9]{2})?$/, 'Bitte geben sie einen gültigen EUR,CT-Betrag ein'),
      costCentre: Yup.string(),
      billingEmail: Yup.string()
        .email('Bitte geben Sie eine gültige Email ein')
    });
    const tenantId = this.props.tenantId;
    const isEditMode = !!tenantId;
    const customerGroupMap = this.state.customerGroupValues;
    const salesStatusMap = this.state.salesStatusValues;
    const addressPlaceholder = 'Musterfirma\nMusterstr. 123\n54321 Musterstadt';
    const defaultInitialValues = {
      id: null,
      title: '',
      tenantId: '',
      systemPath: '',
      administratorEmail: '',
      active: false,
      customerGroup: '',
      salesStatus: 'EXPRESSED_INTEREST',
      lastSalesStatusChangeDate: new Date(),
      baseFee: '',
      address: '',
      protocol: '',
      costCentre: ''
    };
    const getInitialValues = () => {
      if (!isEditMode || !this.state.existingTenant) {
        return defaultInitialValues;
      }
      return Object.assign({}, defaultInitialValues, this.state.existingTenant);
    };
    return (
      <React.Fragment>
        <ErrorModal show={!!this.state.errorMsg}
          errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorMsg} />
        <div>
          <Container>
            <div className="py-3 text-center">
              <h2>{isEditMode ? 'Mandant editieren' : 'Neuer Mandant erstellen'}</h2>
            </div>
            {this.state.errors && !!this.state.errors.length && <div className="alert alert-danger" role="alert">
              Der Server hat mit folgendem Code geantwortet: Error {this.state.errors}
            </div>}
            <Formik enableReinitialize={isEditMode}
              initialValues={getInitialValues()}
              onSubmit={(values, { setSubmitting }) => {
                this.createTenant(values, setSubmitting);
              }}
              validationSchema={validationSchema}>
              {(
                {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit
                }) => {
                const formikPropertiesForChildInputs = {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                };
                return (
                  <form onSubmit={handleSubmit}>
                    <TextInput {...formikPropertiesForChildInputs} fieldName="title" displayName="Titel"
                      placeholder="Mein neuer Mandant" />
                    <TextInput {...formikPropertiesForChildInputs} fieldName="tenantId" displayName="Mandanten-ID"
                      placeholder="mein-neuer-mandant-01" />
                    <TextInput {...formikPropertiesForChildInputs} fieldName="systemPath" displayName="Systempfad"
                      placeholder="mandant-01" />
                    <TextInput {...formikPropertiesForChildInputs} fieldName="administratorEmail"
                      displayName="Email Administrator" />
                    <div className="form-group row">
                      <Col sm={2} />
                      <Col sm={5}>
                        <Button
                          id="resetPassword"
                          hidden={!isEditMode}
                          variant={'primary'}
                          disabled={!values.administratorEmail && !isSubmitting}
                          onClick={() => this.requestNewPassword(values)}>Passwort zurücksetzen
                        </Button>
                      </Col>
                    </div>
                    <div className="form-group row">
                      <label htmlFor="active" className="col-sm-2 col-form-label">Aktiv</label>
                      <Col sm={5}>
                        <div className="form-check">
                          <input className="form-check-input"
                            type="checkbox"
                            id="active"
                            name="active"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={!(values.active || !!values.administratorEmail)}
                            checked={values.active} />
                        </div>
                      </Col>
                    </div>
                    <Select {...formikPropertiesForChildInputs} fieldName="customerGroup" displayName="Kundengruppe"
                      keyValueMap={customerGroupMap} firstOption="Wählen Sie bitte eine Kundengruppe aus" />
                    <Select {...formikPropertiesForChildInputs} fieldName="salesStatus" displayName="Status"
                      keyValueMap={salesStatusMap} />
                    {isEditMode && <div className="form-group row">
                      <label htmlFor="lastSalesStatusChangeDate" className="col-sm-2 col-form-label">Letzte Änderung des
                        Status</label>
                      <Col sm={5}>
                        <DatePicker id="lastSalesStatusChangeDate"
                          className="form-control"
                          name="lastSalesStatusChangeDate"
                          selected={values.lastSalesStatusChangeDate}
                          dateFormat="dd.MM.yyyy HH:mm:ss"
                          readOnly
                          disabled />
                      </Col>
                    </div>}
                    <TextInput {...formikPropertiesForChildInputs} fieldName="baseFee"
                      displayName="Grundgebühr (in EUR)" placeholder="123,45" />
                    <TextInput {...formikPropertiesForChildInputs} fieldName="costCentre"
                      displayName="Kostenstelle" />
                    <TextInput {...formikPropertiesForChildInputs} fieldName="billingEmail"
                      displayName="Rechnungs-Mailadresse" />
                    <TextArea {...formikPropertiesForChildInputs} fieldName="address" displayName="Adresse"
                      placeholder={addressPlaceholder} />
                    <TextArea {...formikPropertiesForChildInputs} rows={10} fieldName="protocol" displayName="Protokoll"
                      placeholder="Protokoll" />
                    <div className="form-group row justify-content-center">
                      <Col sm={2}>
                        <Button type="submit" disabled={isSubmitting}
                          variant={'primary'}>{isEditMode ? 'Speichern' : 'Erstellen'}</Button>
                      </Col>
                      <Col sm={2}>
                        <Link className="btn btn-secondary"
                          to={{ pathname: '/administration/mandanten' }}>Abbrechen</Link>
                      </Col>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}
