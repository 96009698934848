import React from 'react';
import {ErrorMessage, Formik} from 'formik';
import DatePicker, {registerLocale} from 'react-datepicker';
import './TenantBilling.css';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
import BackendServices from '../../../BackendServices';
import {format, setDate, startOfDay} from 'date-fns'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDownload} from '@fortawesome/free-solid-svg-icons/faDownload';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons/faEnvelope';
import {Button} from 'react-bootstrap';
import {centsToEuroCents, euroCentsToCents} from './TenantForm';
import * as Yup from 'yup';

registerLocale('de', de);

// the number of months which are fetched by default (excl. the current month)
const NUMBER_OF_MONTHS_PLUS = 2;

const UNKNOWN_BILLING_STATE = 'unbekannter Status';
export default class TenantBilling extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      billingLoaded: false
    };
  }
  
  async componentDidMount() {
    const endDate = startOfDay(setDate(new Date(), 1));   // set day of month to: 1
    const startDate = startOfDay(new Date(endDate).setMonth(endDate.getMonth() - NUMBER_OF_MONTHS_PLUS));
    const downloadUrl = BackendServices.getDownloadURL(this.props.tenantId);
    this.setState({
      startDate: startDate,
      endDate: endDate,
      downloadURL: downloadUrl
    });
    
    await this.fetchBillingInformation(startDate, endDate);
  }
  
  async fetchBillingInformation(startDate, endDate) {
    let requests = [];
    let iterationDate = new Date(startDate);
    while (endDate >= iterationDate) {
      requests.push({
        billingDate: new Date(iterationDate),
        month: format(iterationDate, 'MMMM').toUpperCase(),
        year: iterationDate.getFullYear()
      });
      iterationDate.setMonth(iterationDate.getMonth() + 1);
    }
    
    const billingData = await Promise.all(
      requests.map(async request => {
        const billingInfoResponse =
          await BackendServices.fetchTenantBillingData(this.props.tenantId, request.month, request.year);
        if (BackendServices.wasSuccessful(billingInfoResponse)) {
          const billingInfo = await billingInfoResponse.json();
          billingInfo.storageUsedInBytes = billingInfo.storageUsedInBytes ? billingInfo.storageUsedInBytes : 0;
          return {
            ...billingInfo,
            billingDate: request.billingDate
          }
        } else {
          return {
            qualityChecks: 'Fehler',
            complaintRecording: 'Fehler',
            documentPhotoFilings: 'Fehler',
            storageUsedInBytes: 0,
            billingDate: request.billingDate
          }
        }
      })
    );
    
    this.setState({
      ...this.state,
      billingData: billingData,
      billingLoaded: true
    });
  }

  async sendBillingPerMail(date, index) {
    let request = {
      month: format(date, 'MMMM').toUpperCase(),
      year: date.getFullYear()
    }
    let response = await BackendServices.sendBillingPerMail(this.props.tenantId, request.month, request.year);
    if (BackendServices.wasSuccessful(response)) {
      let data = [ ...this.state.billingData ];
      data[index].status = 'BILLING_SENT_VIA_EMAIL';
      this.setState({
        billingData: data,
      });

    }

  }

  async createBilling(date, index) {
    let request = {
      month: format(date, 'MMMM').toUpperCase(),
      year: date.getFullYear()
    };
    
    const billingResponse =
      await BackendServices.createBilling(this.props.tenantId, request.month, request.year);
    if (BackendServices.wasSuccessful(billingResponse)) {
      const billingInfo = await billingResponse.json();
      billingInfo.storageUsedInBytes = billingInfo.storageUsedInBytes ? billingInfo.storageUsedInBytes : 0;
      billingInfo.billingDate = date;
      let data = this.state.billingData;
      data[index] = billingInfo;
      this.setState({
        ...this.state,
        billingData: data,
      });
      
    } else {
      return {
        qualityChecks: 'Fehler',
        complaintRecording: 'Fehler',
        documentPhotoFilings: 'Fehler',
        storageUsedInBytes: 0,
        billi7ngDate: request.billingDate
      }
    }
  }
  
  setStartDate = (startDate) => {
    const newStartDate = startOfDay(startDate);
    this.setState({
      ...this.state, startDate: newStartDate,
      billingLoaded: false
    });
    
    this.fetchBillingInformation(newStartDate, this.state.endDate);
  };
  
  setEndDate = (endDate) => {
    const newEndDate = startOfDay(endDate);
    this.setState({
      ...this.state, endDate: newEndDate,
      billingLoaded: false
    });
    
    this.fetchBillingInformation(this.state.startDate, newEndDate);
  };
  
  
  generateDownloadLink(date) {
    const caption = format(date, 'MMMM yyyy', {locale: de});
    const path = this.state.downloadURL + 'year/' + date.getFullYear() + '/month/' + format(date, 'MMMM').toUpperCase() + '/pdf';
    return (
      <a href={path}>
        <FontAwesomeIcon title={'Abrechnung für ' + caption + ' herunterladen'}
          className={'download-abrechnung'}
          icon={faDownload}/>
      </a>
    )
  }
  generateEmailButton(billing, index) {
    const caption = format(billing.billingDate, 'MMMM yyyy', {locale: de});
    if (this.props.billingEmail) {
      let hide = billing.status === null
        || billing.status === 'BILLING_NOT_CREATED'
        || this.dateIsInCurrentMonth(billing.billingDate);
      return (
        <Button className='send-mail' variant='' hidden={hide} onClick={() => this.sendBillingPerMail(billing.billingDate, index)}>
          <FontAwesomeIcon title={'Abrechnung für ' + caption + ' per Mail versenden'}
            className={'send-mail-image'}
            icon={faEnvelope}/>
        </Button>
      )
    }
    return null
  }
  render() {
    const validationSchema = Yup.object().shape({
      sumReceived: Yup.string()
        .matches(/^[0-9]{1,6}(?:,[0-9]{2})?$/, 'Bitte geben sie einen gültigen EUR,CT-Betrag ein'),
    })
    return (
      <div className="container overflow-auto">
        <div className="row mt-3 mb-3">
          <h2>Abrechnung</h2>
        </div>
        <div className="mb-3">
          Zeitraum:
          <DatePicker
            selected={this.state.startDate}
            onChange={date => this.setStartDate(date)}
            selectsStart
            startDate={this.state.startDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            locale="de"
            className="ml-2 mr-2"
          />
          bis
          <DatePicker
            selected={this.state.endDate}
            onChange={date => this.setEndDate(date)}
            selectsEnd
            startDate={this.state.endDate}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            locale="de"
            className="ml-2"
          />
        </div>
        
        {!this.state.billingLoaded &&
        <div>Abrechnungen laden...</div>
        }
        {this.state.billingLoaded &&
        <div className="row col-auto">
          <table className="table table-bordered">
            <thead className={'thead-light'}>
              <tr>
                <th/>
                {this.state.billingData.map((billing, index) => {
                  const caption = format(billing.billingDate, 'MMMM yyyy', {locale: de});
                  const link = this.generateDownloadLink(billing.billingDate);
                  const mailButton = this.generateEmailButton(billing, index)
                  return (
                    <th key={index}>
                      {caption}
                      {link}
                      {mailButton}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Status</td>
                {this.state.billingData.map((billing, index) => {
                  let billingStatus;
                  switch(billing.status) {
                    case null:
                    case 'BILLING_NOT_CREATED':
                      billingStatus = 'Abrechnung nicht durchgeführt'
                      break;
                    case 'BILLING_CREATED':
                      billingStatus = 'Abrechnung durchgeführt'
                      break;
                    case 'BILLING_SENT_VIA_EMAIL':
                      billingStatus = 'Mail versandt'
                      break;
                    default:
                      billingStatus = UNKNOWN_BILLING_STATE
                  }
                  return (
                    <td key={index}>{billingStatus}</td>
                  )
                })}
              </tr>
              <tr>
                <td>Prüfungen</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td key={index}>{billing.qualityChecks}</td>
                  )
                })}
              </tr>
              <tr>
                <td>Aufgaben</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td key={index}>{billing.complaintRecording}</td>
                  )
                })}
              </tr>
              <tr>
                <td>Dokus</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td key={index}>{billing.documentPhotoFilings}</td>
                  )
                })}
              </tr>
              <tr>
                <td>Kosten</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td className="text-nowrap" key={index}>
                      {(billing.totalCostsInCents / 100).toFixed(2) + ' €'}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Aus Kontingent bezahlt</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td className="text-nowrap" key={index}>
                      {(-billing.payedFromContingentInCents / 100).toFixed(2) + ' €'}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Offen</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td className="text-nowrap" key={index}>
                      {((billing.totalCostsInCents + billing.payedFromContingentInCents) / 100).toFixed(2) + ' €'}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td>Benutzter Speicherplatz</td>
                {this.state.billingData.map((billing, index) => {
                  return (
                    <td className="text-nowrap" key={index}>
                      {(billing.storageUsedInBytes / 1024 / 1024 / 1024) + ' GB'}
                    </td>
                  )
                })}
              </tr>
              <tr>
                <td className= "text-nowrap">Summe erhalten</td>
                {this.state.billingData.map((billing, index) => {
                  const isEditMode =
                      billing.status !== undefined
                      && billing.status !== 'BILLING_NOT_CREATED'
                      && billing.status !== UNKNOWN_BILLING_STATE
                  const sumReceived = billing.sumReceivedInCents ? centsToEuroCents(billing.sumReceivedInCents) : ''
                  const initValues =  { sumReceived: sumReceived }
                  return (
                    <td key={index}>
                      <Formik enableReinitialize={isEditMode}
                        initialValues={initValues}
                        onSubmit={(values, {setSubmitting}) => {
                          this.setSumReceived(this.props.tenantId, billing, values, setSubmitting);
                        }}
                        validationSchema={validationSchema}>
                        {(
                          {
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit
                          }) => {
                          let fieldName = 'sumReceived';
                          return (
                            <form onSubmit={handleSubmit}>
                              <div className="form-group">
                                <input id={fieldName}
                                  disabled={!isEditMode}
                                  type='text'
                                  name={fieldName}
                                  value={values['sumReceived']}
                                  className={`form-control ${touched[fieldName] && errors[fieldName] ? 'is-invalid' : ''}`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder=''/>
                                <ErrorMessage component="div" name={fieldName} className="invalid-feedback"/>
                              </div>
                              <Button type="submit" disabled={isSubmitting || !isEditMode} variant={'primary'}>
                                {'Speichern'}
                              </Button>
                            </form>
                          )
                        }}
                      </Formik>
                    </td>
                  )})}
              </tr>
              <tr>
                <td />
                {this.state.billingData.map((billing, index) => {
                  const billingDate = billing.billingDate;
                  if (this.dateIsInCurrentMonth(billingDate)) {
                    return null
                  }
                  const caption = 'Abrechnung durchführen'
                  const hide = billing.status !== null && billing.status !== 'BILLING_NOT_CREATED'
                  return (
                    <td key={index}>
                      <Button variant={'success'} hidden={hide}
                        onClick={() => this.createBilling(billing.billingDate, index)}>{caption}
                      </Button>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          </table>
        </div>
        }
      </div>
    );
  }

  dateIsInCurrentMonth(billingDate) {
    const now = new Date();
    return now.getFullYear() <= billingDate.getFullYear() && now.getMonth() <= billingDate.getMonth();
  }

  setSumReceived(tenantId, billing, values, isSumbitting) {
    let sumReceivedInCents = euroCentsToCents(values.sumReceived);
    if (sumReceivedInCents == null) {
      sumReceivedInCents = 0
    }
    BackendServices.setSumReceivedInCents(tenantId, billing.year, billing.month, sumReceivedInCents)
    isSumbitting(false);
  }
}
