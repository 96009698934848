import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import i18n from '../i18n';
import queryString from 'query-string';

class PasswordConfirmationPage extends React.Component {

  extractLanguage(pathParams) {
    let lng;
    if (pathParams) {
      const params = queryString.parse(pathParams);
      lng = params.lng;
    }

    return lng;
  }

  render() {
    i18n.changeLanguage(this.extractLanguage(this.props.location.search));

    return (
      <Container>
        <div className="py-3 text-center">
          <h2 id="password-confirmation">{i18n.t('password.confirmation.heading')}</h2>
        </div>
        <Row>
          <p>
            {i18n.t('password.confirmation.paragraph')}
            <Trans i18nKey="password.confirmation.sign-in-prompt">
              You can <Link id="redirect-sign-in" to={{ pathname: '/login' }}>sign in</Link> now!
            </Trans>
          </p>
        </Row>
      </Container>
    );
  }
}

export default PasswordConfirmationPage;
