import KeyValueTableRow from './KeyValueTableRow';

export default class StatusRow extends KeyValueTableRow {
  constructor(props) {
    super(props);
    super.state = {
      key : 'Status',
      value: this.getDisplayStateText(props.value)
    };
  }

  getDisplayStateText(stateEnumString) {
    let stateText = '';
    switch (stateEnumString) {
      case 'INITIATED':
        stateText = 'Initiiert';
        break;
      case 'ACTIVE':
        stateText = 'Aktiv';
        break;
      case 'REMOVING_DATA':
        stateText = 'Daten werden gelöscht...';
        break;
      case 'REMOVED_DATA':
        stateText = 'Daten wurden gelöscht';
        break;
      case 'REMOVING_DATA_FAILED':
        stateText = 'Fehler beim Löschen der Daten';
        break;
      case 'INITIALIZATION_FAILED':
        stateText = 'Initiierung fehlgeschlagen';
        break;
      default:
        stateText = stateEnumString;
        break
    }
    return stateText;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    super.setState({value: this.getDisplayStateText(nextProps.value)});
  }
}