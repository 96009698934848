import React from 'react';
import BackendServices from '../BackendServices';
import {connect} from 'react-redux';
import * as auth from '../redux/Auth'
import {Redirect} from 'react-router-dom';

class Logout extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      logoutSuccessful: false
    };
  }

  async componentDidMount() {
    if (await BackendServices.logout()) {
      this.props.setLogout();
      this.setState({logoutSuccessful: true});
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.logoutSuccessful &&
        <Redirect to="/login"/>
        }
      </React.Fragment>
    );
  }
}

export default connect(
  undefined,
  (dispatch) => ({
    setLogout: () => dispatch(auth.actions.setLogout())
  })
)(Logout);
