import React from 'react';
import BackendServices from '../../../BackendServices';
import ErrorModal from '../../ErrorModal';
import { findMatchingErrorMessage } from '../../../util';
import ReactSwitch from 'react-switch';
import { ADMIN_ROLE, USER_ROLE } from '../../../redux/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Button, Container, Row } from 'react-bootstrap';
import i18n from '../../../i18n';

const adminEmail = 'service@nesseler.de';

export default class UserOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersLoaded: false,
      filter: '',
      showUserIndexFrom: 0,
      pageSize: 10,
      nextPageAvailable: false
    };
  }

  async componentDidMount() {
    const response = await BackendServices.fetchAllUsers();

    if (BackendServices.wasSuccessful(response)) {
      const users = await response.json();
      this.setState({
        users: users,
        usersFiltered: users,
        usersLoaded: true,
        nextPageAvailable: users.length > this.state.pageSize
      });
    } else {
      this.setState({ errorMsg: i18n.t(await findMatchingErrorMessage(response)) });
    }
  }

  handleCloseErrorMsg = () => {
    this.setState({ errorMsg: null });
  };

  handleChange = (event) => {
    const userNameToFilter = (event.target.value || '').trim().toLowerCase();
    this.setState({ filter: userNameToFilter, showUserIndexFrom: 0 }, this.filterUserList)
  };

  filterUserList = () => {
    const allUsers = this.state.users;
    const filteredUsers = this.state.filter
      ? allUsers.filter(user => user.email.toLowerCase().includes(this.state.filter))
      : allUsers;
    this.setState({ usersFiltered: filteredUsers });
    this.setState({ nextPageAvailable: filteredUsers.length > this.state.showUserIndexFrom + this.state.pageSize });
  }

  render() {
    return (
      <React.Fragment>
        <ErrorModal show={!!this.state.errorMsg} errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorMsg} />

        <div>
          <Container>
            <Row className="mt-3 mb-3">
              <h2>Benutzer</h2>
            </Row>
            <input className="row mt-3 mb-3 form-control" type='text' onChange={this.handleChange}
              placeholder="Benutzer nach E-Mail filtern" />
            {!this.state.usersLoaded &&
              <div>Benutzer laden...</div>
            }
            {this.state.usersLoaded &&
              <div className="row col-auto mb-4">
                <Button
                  id="button-previous-page"
                  variant={'primary'}
                  className="col-1"
                  disabled={this.state.showUserIndexFrom === 0}
                  onClick={() => this.previousPage()}>
                  <FontAwesomeIcon icon={faAngleLeft} color="white" size="2x" />
                </Button>
                <label id="usersFromToLabel" className="col d-flex justify-content-center ">{this.getCurrentFromToString()}</label>
                <Button type="button"
                  id="button-next-page"
                  variant={'primary'}
                  className="col-1"
                  disabled={!this.state.nextPageAvailable}
                  onClick={() => this.nextPage()}>
                  <FontAwesomeIcon icon={faAngleRight} color="white" size="2x" />
                </Button>
              </div>
            }
            {this.state.usersLoaded &&
              <div className="row col-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">E-Mail</th>
                      <th scope="col">Rolle User</th>
                      <th scope="col">Rolle Admin</th>
                      <th scope="col">Gesperrt</th>
                      <th scope="col">Initialisiert</th>
                      <th scope="col">Mandanten benachrichtigen</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.usersFiltered
                      .slice(this.state.showUserIndexFrom, this.state.showUserIndexFrom + this.state.pageSize)
                      .map((user) => {
                        return (
                          <tr key={user.email}>
                            <td>{user.email}</td>
                            <td data-useremail={user.email}>
                              <ReactSwitch className="react-switch"
                                disabled={user.email === adminEmail || user.locked}
                                checked={user.active}
                                onChange={this.toggleActive}
                              /></td>
                            <td data-useremail={user.email}>
                              <ReactSwitch className="react-switch"
                                disabled={user.email === adminEmail}
                                checked={user.admin}
                                onChange={this.toggleAdmin}
                              /></td>
                            <td data-useremail={user.email}>
                              <ReactSwitch className="react-switch"
                                disabled={user.email === adminEmail}
                                checked={user.locked}
                                onChange={this.toggleLocked}
                              /></td>
                            <td data-useremail={user.email} align="center">
                              {user.initialized && (<FontAwesomeIcon icon={faCheck} color="green" size="2x" />)}
                              {!user.initialized && (<FontAwesomeIcon icon={faTimes} color="red" size="2x" />)}
                            </td>
                            <td data-useremail={user.email}>
                              <Button
                                id={'notifyTenant-' + user.email}
                                variant={'primary'}
                                disabled={!user.initialized}
                                onClick={() => this.notifyTenants(user.email)}>Mandanten benachrichten
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            }
          </Container>
        </div>
      </React.Fragment>
    )
  }

  nextPage = () => {
    const from = this.state.showUserIndexFrom
    this.setState({ showUserIndexFrom: from + 10 }, this.filterUserList);
  }

  previousPage = () => {
    const from = this.state.showUserIndexFrom
    this.setState({ showUserIndexFrom: from - 10 }, this.filterUserList);
  }

  getCurrentFromToString = () => {
    if (this.state.usersFiltered.length === 0) {
      return 'Keine Benutzer für aktuellen Filter gefunden'
    }
    let upperBoundary = this.state.showUserIndexFrom + this.state.pageSize;
    const usersFilteredLength = this.state.usersFiltered.length;
    if (upperBoundary > usersFilteredLength) {
      upperBoundary = usersFilteredLength;
    }
    return `${this.state.showUserIndexFrom + 1} - ${upperBoundary} von ${usersFilteredLength}`;
  }

  notifyTenants = (userEmail) => {
    BackendServices.activateUserOnTenants(userEmail);
  };

  toggleActive = async (checked, event) => {
    let user = this.getUserForToggling(event);

    await this.toggleRole(user, user.active, USER_ROLE);
  };

  toggleAdmin = async (checked, event) => {
    let user = this.getUserForToggling(event);

    await this.toggleRole(user, user.admin, ADMIN_ROLE);
  };

  getUserForToggling(event) {
    const userEmail = event.target.parentElement.parentElement.getAttribute('data-useremail');
    return this.state.users.filter(u => u.email === userEmail)[0];
  }

  async toggleRole(user, addOrRemove, role) {
    const response = addOrRemove ?
      await BackendServices.removeRolesFromUser(user.email, [role]) :
      await BackendServices.addRolesToUser(user.email, [role]);

    if (BackendServices.wasSuccessful(response)) {
      const currentRoles = await response.json();

      let changedUser = { ...user };
      changedUser.active = currentRoles.indexOf(USER_ROLE) >= 0;
      changedUser.admin = currentRoles.indexOf(ADMIN_ROLE) >= 0;

      let newUsers = this.state.users.map(u => u.email === changedUser.email ? changedUser : u);
      this.setState({ users: newUsers }, this.filterUserList);
    } else {
      this.setState({ errorMsg: i18n.t(await findMatchingErrorMessage(response)) });
    }
  }

  toggleLocked = async (locked, event) => {
    let user = this.getUserForToggling(event);

    const response = user.locked ?
      await BackendServices.unlockUser(user.email) :
      await BackendServices.lockUser(user.email);

    if (BackendServices.wasSuccessful(response)) {
      const currentUser = await response.json();
      let changedUser = { ...currentUser };
      let newUsers = this.state.users.map(u => u.email === changedUser.email ? changedUser : u);
      this.setState({ users: newUsers }, this.filterUserList);
    } else {
      this.setState({ errorMsg: i18n.t(await findMatchingErrorMessage(response)) })
    }
  }
}
