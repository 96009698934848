import React from 'react';
import './TenantItem.css';
import NqImage from '../NqImage';
import BackendServices from '../../BackendServices';

export default class TenantItem extends React.Component {
  render() {
    const tenantPathPrefix = BackendServices.getTenantPathPrefix();
    const displayName = this.props.tenant.portalName || this.props.tenant.title;
    return (
      <li className="list-group-item">
        <a href={`/${tenantPathPrefix}/${this.props.tenant.systemPath}`}>
          <NqImage alt={this.props.tenant.title}
            src= { `/${tenantPathPrefix}/${this.props.tenant.systemPath}/staticresources/logo_300.png` }/>
          <p>{displayName}</p>
        </a>
      </li>
    );
  }
}
