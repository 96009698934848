import React from 'react';
import TenantItem from './TenantItem';

export default class TenantList extends React.Component {

  render() {
    return (
      <ul className="d-flex flex-row flex-wrap justify-content-center">
        {this.props.tenants.map((tenant, index) =>
          <TenantItem key={index} tenant={tenant}/>
        )}
      </ul>
    );
  }
}
