import './TenantOverview.css';
import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import BackendServices from '../../../BackendServices';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Container, Row} from 'react-bootstrap';

export default class TenantOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tenants: [],
      customerGroups: [],
      salesStatuses: [],
      tenantId: undefined
    };
  }
  
  componentDidMount() {
    BackendServices.fetchCustomerSalesStatus().then((salesStatuses) => {
      this.setState({...this.state, salesStatuses: salesStatuses})
    });
    BackendServices.fetchCustomerGroup().then((customerGroups) => {
      this.setState({...this.state, customerGroups: customerGroups})
    });
    
    BackendServices.fetchAllTenants().then((tenants) => {
      this.setState({tenants: tenants})
    });
    
  }
  
  setTenantId = (event) => {
    if(event.currentTarget.id){
      this.setState({tenantId: event.currentTarget.id});
    }
  };

  sortByCostCentre = (a, b) => {
    if(a.costCentre === null || a.costCentre === '') return 1;
    if(b.costCentre === null || b.costCentre === '') return -1;
    if(a.costCentre === b.costCentre) return 0;
    return a.costCentre < b.costCentre ? -1 : 1;
  }
  
  render() {
    if (this.state.tenantId) {
      return <Redirect to={`/administration/mandanten/editieren/${this.state.tenantId}`}/>;
    }
    return (
      <div>
        <Container>
          <Row className="mt-3">
            <h2>Mandanten</h2>
          </Row>
          <Row className="justify-content-end">
            <Link className="menuItem" to={{pathname: '/administration/mandanten/neu'}}>
              <FontAwesomeIcon icon={faPlus} style={{color: 'grey'}}/></Link>
          </Row>
          <Row className="col-auto">
            <table className="table table-hover">
              <thead className={'thead-light'}>
                <tr>
                  <th scope="col">Firma</th>
                  <th scope="col">Kundengruppe</th>
                  <th scope="col">Status</th>
                  <th scope="col">Statusänderung</th>
                  <th scope="col">Grundgebühr</th>
                  <th scope="col">Adresse</th>
                  <th scope="col">Kostenstelle</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tenants.sort(this.sortByCostCentre).map((tenant, index) => {
                  return (
                    <tr key={index} id={tenant.id} onClick={this.setTenantId}>
                      <td>{tenant.title}</td>
                      <td>{typeof this.state.customerGroups !== 'undefined' ? this.state.customerGroups[tenant.customerGroup] : ''}</td>
                      <td>{typeof this.state.salesStatuses !== 'undefined' ? this.state.salesStatuses[tenant.salesStatus] : ''}</td>
                      <td>{tenant.lastSalesStatusChangedDate}</td>
                      <td>{tenant.baseFee}</td>
                      <td>{tenant.address}</td>
                      <td>{tenant.costCentre}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Row>
        </Container>
      </div>
    )
  }
}
