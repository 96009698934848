import React from 'react';
import BackendServices from '../../../BackendServices';
import {Button, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';

export default class DeleteInNqWebComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoProject: props.demoProject,
    };
    this.getOnClick = this.getOnClick.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ demoProject: nextProps.demoProject });
  }
    
  async getOnClick() {
    let response = await BackendServices.deleteDemoProjectContents(this.state.demoProject.email);
    if (response.ok) {
      const updatedDemoProject = await response.json();
      this.setState({showSuccessModal: true, demoProject: updatedDemoProject})
      this.props.callback(updatedDemoProject);
    } else {
      this.setState({showErrorModal: true})
    }
  }

  render() {
    return (
      <React.Fragment>
        <ErrorModal show={this.state.showErrorModal} callback={() => {
          this.setState({showErrorModal: false});
        }}/>
        <SuccessModal show={this.state.showSuccessModal} callback={() => {
          this.setState({showSuccessModal: false});
        }}/>
        <Button className={'col-5'} variant="warning" disabled={!this.isButtonEnabled()} onClick={this.getOnClick}>
            Projektdaten in nq-web löschen
        </Button>
      </React.Fragment>)
  }
  isButtonEnabled() {
    return this.state.demoProject.state === 'ACTIVE' || this.state.demoProject.state ===  'REMOVING_DATA_FAILED'
  }
}

function ErrorModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.callback} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'red'}}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Beim Löschen des Demoprojekts aus dem Demosystem ein Fehler aufgetreten.
            Bitte versuchen Sie es erneut bzw. kontaktieren Sie den Administrator.</Modal.Body>
      </Modal>
    </>
  );
}

function SuccessModal(props) {
  return (
    <>
      <Modal show={props.show} onHide={props.callback} centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FontAwesomeIcon icon={faCheckCircle} style={{color: 'green'}}/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>Das Demoprojekt wird nun aus dem Demosystem entfernt.</Modal.Body>
      </Modal>
    </>
  );
}