import React from 'react';
import Menu from './Menu.js';
import PrivateRoute from '../PrivateRoute';
import * as auth from '../../redux/Auth';
import TenantOverview from './tenants/TenantOverview';
import {Switch} from 'react-router-dom';
import UserOverview from './users/UserOverview';
import TenantDetails from './tenants/TenantDetails';
import DemoProjectOverview from './demoprojekte/DemoProjectOverview';
import {Container} from 'react-bootstrap';

class Administration extends React.Component {
  render() {
    return (
      <Container className="h-100">
        <Menu/>
        <Switch>
          <PrivateRoute path="/administration/mandanten/editieren/:tenantId" withRole={auth.ADMIN_ROLE}>
            <TenantDetails/>
          </PrivateRoute>
          <PrivateRoute path="/administration/mandanten/neu" withRole={auth.ADMIN_ROLE}>
            <TenantDetails/>
          </PrivateRoute>
          <PrivateRoute path="/administration/mandanten/" withRole={auth.ADMIN_ROLE}>
            <TenantOverview/>
          </PrivateRoute>
          <PrivateRoute path="/administration/benutzer/" withRole={auth.ADMIN_ROLE}>
            <UserOverview/>
          </PrivateRoute>
          <PrivateRoute path="/administration/demoprojekte/" withRole={auth.ADMIN_ROLE}>
            <DemoProjectOverview/>
          </PrivateRoute>
          <PrivateRoute path="/administration">
            <div/>
          </PrivateRoute>
        </Switch>
      </Container>
    );
  }
}

export default Administration;
