import React from 'react';
import {withRouter} from 'react-router-dom';
import TenantForm from './TenantForm';
import TenantBilling from './TenantBilling';
import TenantTransactions from './TenantTransactions';

export class TenantDetailsComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      billingEmail: undefined
    }
  }

  _handleBillingEmail = (billingEmail) => {
    this.setState({
      billingEmail
    })
  }

  render() {
    const tenantId = this.props.match.params.tenantId;

    return (
      <React.Fragment>
        <div/>
        <TenantForm tenantId={tenantId} onBillingEmail={this._handleBillingEmail}/>

        {/* transaction information is only displayed for existing tenants */}
        {tenantId &&
        <TenantTransactions tenantId={tenantId}/>
        }
        {/* billing information is only displayed for existing tenants */}
        {tenantId &&
        <TenantBilling tenantId={tenantId} billingEmail={this.state.billingEmail}/>
        }
      </React.Fragment>
    );
  }
}

export default withRouter(TenantDetailsComponent)
