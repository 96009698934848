import React from 'react';
import PropTypes from 'prop-types';

export default class Modal extends React.Component {

  static propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    onClosed: PropTypes.func,
    titel: PropTypes.node,
    children: PropTypes.node,
    large: PropTypes.bool
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.bodyAddModalOpenClass();
    }

    if (prevProps.show && !this.props.show) {
      this.bodyRemoveModalOpenClass();
    }
  }

  componentWillUnmount() {
    if (this.props.show) {
      this.bodyRemoveModalOpenClass();
    }
  }

  bodyAddModalOpenClass() {
    for (const bodyEl of document.getElementsByTagName('body')) {
      bodyEl.classList.add('modal-open');
    }
  }

  bodyRemoveModalOpenClass() {
    for (const bodyEl of document.getElementsByTagName('body')) {
      bodyEl.classList.remove('modal-open');
    }
  }

  render() {
    return (
      <div className={`modal fade ${this.props.show ? 'show' : ''}`} tabIndex="-1">
        <div className={`modal-dialog modal-dialog-centered ${this.props.large ? 'modal-lg' : ''}`}>
          <div className="modal-content">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
