import React from 'react';
import BackendServices from '../../BackendServices';
import TenantList from './TenantList';
import {Link, withRouter} from 'react-router-dom';
import queryString from 'query-string';
import {isDevelopmentEnv} from '../../util';
import {connect} from 'react-redux';
import * as auth from '../../redux/Auth';
import {Button} from 'react-bootstrap';

export class TenantSelectionComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tenantsFetched: false
    };
  }

  componentDidMount() {
    BackendServices.fetchTenants().then(tenants => {
      this.processTenants(tenants);
    });
  }

  isAdmin() {
    return this.props.userRoles.indexOf(auth.ADMIN_ROLE) >= 0;
  }

  processTenants(tenants) {
    tenants = tenants.filter(tenant => tenant.active);
    let newState = {
      tenantsFetched: true,
      tenants: tenants,
      tenantsFiltered: tenants
    };
    let {redirectUrl, tenantToRedirectTo} = this.extractRedirectionInfos(window.location.search, tenants);

    if (redirectUrl && tenantToRedirectTo) {
      // deep link for valid tenant
      this.redirectToUrl(redirectUrl);

    } else if (redirectUrl && !tenantToRedirectTo) {
      // deep link for invalid tenant
      this.setState({
        ...newState,
        tenantRedirectForbidden: true
      });

    } else if (tenants.length === 1 && !this.isAdmin()) {
      // user has only one tenant
      this.redirectToUrl(this.createTenantUrlPrefix(tenants[0].systemPath));

    } else {
      // user has to choose between multiple tenants
      this.setState(newState);
    }
  }

  createTenantUrlPrefix(tenantSystemPath) {
    return `/${BackendServices.getTenantPathPrefix()}/${tenantSystemPath}`;
  }

  extractRedirectionInfos(pathParams, allAccessibleTenants) {
    let redirectUrl, tenantId;
    if (pathParams) {
      const params = queryString.parse(pathParams);
      redirectUrl = params.redirect;
      tenantId = params.tenantId;
    }

    let tenantToRedirectTo;
    if (redirectUrl != null) {
      const redirectionTenants = allAccessibleTenants.filter((tenant) => tenant.tenantId === tenantId);
      tenantToRedirectTo = redirectionTenants.length > 0 ? redirectionTenants[0] : null;
      if (tenantToRedirectTo) {
        redirectUrl = this.createTenantUrlPrefix(tenantToRedirectTo.systemPath) + '/' + redirectUrl;
      }
    }

    return {redirectUrl, tenantToRedirectTo};
  }

  redirectToUrl(url) {
    if (isDevelopmentEnv()) {
      this.props.history.push(url);
    } else {
      window.location.href = url;
    }
  }

  handleChange = (event) => {
    const tenantNameToFilter = (event.target.value || '').trim().toLowerCase();
    const allTenants = this.state.tenants;
    const filteredTenants = tenantNameToFilter
      ? allTenants.filter(tenant => tenant.title.toLowerCase().indexOf(tenantNameToFilter) > -1)
      : allTenants;
    this.setState(
      Object.assign({}, this.state, {
        tenantsFiltered: filteredTenants
      }));
  };

  render() {
    if (!this.state.tenantsFetched) {
      return (
        <div/>
      );
    }

    return (
      <div className="container h-100">

        <div className="row h-50 align-items-end">
          <div className="col-md-6 col-lg-6 mx-auto">
            {this.isAdmin() && <Link to="/administration">
              <Button variant={'primary'} className="mb-4" block={true}>
                Administration
              </Button>
            </Link>}
            <div className="mb-4">Bitte wählen Sie das gewünschte Mandantensystem aus:</div>
            <input className="form-control" type='text' onChange={this.handleChange}/>
            {this.state.tenants.length === 0 &&
            <div className="alert alert-danger" role="alert">Sie sind für kein System freigeschaltet.</div>}
            {this.state.tenantRedirectForbidden &&
            <div className="alert alert-danger" role="alert">Für diesen Mandanten sind Sie nicht freigeschaltet.</div>}
          </div>
        </div>

        <div className="row mt-4 h-50">
          <div className="col-md-12 col-lg-12">
            <TenantList tenants={this.state.tenantsFiltered}/>
          </div>
        </div>
      </div>
    );
  }
}

export const TenantSelection = connect(
  state => ({
    userRoles: state.userRoles
  }),
  undefined
)(TenantSelectionComponent);

export default withRouter(TenantSelection);
