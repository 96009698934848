import React from 'react';

export default class KeyValueTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: props.fieldName,
      value: props.value,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ key: nextProps.fieldName, value: nextProps.value });
  }

  render() {
    return (
      <tr className={'d-flex'}>
        <td className={'col-4'}>{this.state.key}</td>
        <td className={'col-8'}>{this.state.value}</td>
      </tr>
    )
  }
}