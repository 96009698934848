const TEST_ENV_PATH_PREFIX = 'gateway_';

export const isDevelopmentEnv = () => (process && process.env && process.env.NODE_ENV !== 'production');

export const extractContextPathPrefix = () => {
  const currentPrefix = window.location.pathname.split('/')[1];
  if (currentPrefix.startsWith(TEST_ENV_PATH_PREFIX)) {
    return currentPrefix;
  } else {
    return '';
  }
};

export const DEFAULT_ERROR_MESSAGE_KEY = 'error.default';

export const findMatchingErrorMessage = async (response, messages = {}) => {
  let payload;
  if (response.headers.get('content-type') === 'application/json') {
    payload = await response.json();
  }

  if (payload && messages[payload.errorCode]) {
    return messages[payload.errorCode];
  }
  return DEFAULT_ERROR_MESSAGE_KEY;
};

// pls see https://itnext.io/simple-react-form-validation-with-formik-yup-and-or-spected-206ebe9e7dcc
export const validateUsingCustomYupSchema = (getValidationSchema) => {
  return (values) => {
    const validationSchema = getValidationSchema(values);
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {}
    } catch (error) {
      return getErrorsFromValidationError(error)
    }
  }
};
const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;
  return validationError.inner.reduce((errors, error) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    }
  }, {})
};
