import React from 'react';
import Reaptcha from 'reaptcha';
import { Redirect } from 'react-router-dom';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';

import BackendServices from '../BackendServices';
import ErrorModal from './ErrorModal';
import { findMatchingErrorMessage } from '../util';
import { Button } from 'react-bootstrap';
import i18n from '../i18n';
import queryString from 'query-string';

class RestorePasswordPage extends React.Component {
  errorMessages = {
    RECAPTCHA_VALIDATION_ERROR: 'Die Validierung des reCaptchas ist fehlgeschlagen. Bitte versuchen Sie es erneut.'
  };

  constructor(props) {
    super(props);
    this.state = {
      isRestorePasswordRequested: false,
      reCaptchaSiteKey: null,
      reCaptchaToken: null,
      errorMsg: null
    };
  }

  componentDidMount() {
    if (!this.state.reCaptchaSiteKey) {
      BackendServices.getReCaptchaSiteKey().then(this.setReCaptchaSiteKey);
    }
  }

  extractLanguage(pathParams) {
    let lng;
    if (pathParams) {
      const params = queryString.parse(this.props.location.search);
      lng = params.lng;
    }

    return lng;
  }

  setReCaptchaSiteKey = (reCaptchaSiteKey) => {
    this.setState({ ...this.state, reCaptchaSiteKey: reCaptchaSiteKey });
  };

  resetCaptcha = () => {
    this.setState({ reCaptchaToken: null });
  };

  async requestNewPassword(values, setSubmitting) {
    let response = await BackendServices.requestNewPassword(
      values.useremail, this.state.reCaptchaToken, i18n.language
    );
    if (BackendServices.wasSuccessful(response)) {
      this.setState({ isRestorePasswordRequested: true });
    } else {
      this.setState({
        ...this.state,
        errorMsg: i18n.t(await findMatchingErrorMessage(response, this.errorMessages))
      });
    }

    this.resetCaptcha();
    setSubmitting(false);
  }

  setReCaptchaToken = (token) => {
    this.setState({ reCaptchaToken: token });
  };

  handleCloseErrorMsg = () => {
    this.setState({ errorMsg: null });
    this.reCaptcha.reset();
  };

  render() {
    if (this.state.isRestorePasswordRequested) {
      return <Redirect to="/passwort-vergabe-link-versendet" />;
    }
    return (
      <React.Fragment>
        <ErrorModal show={!!this.state.errorMsg}
          errorMsg={this.state.errorMsg} onClose={this.handleCloseErrorMsg}
        />
        <div className="container h-100">
          <div className="py-3 text-center">
            <h2>{i18n.t('password.request.new')}</h2>
          </div>
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card-signin">
                <Formik
                  initialValues={{ useremail: '' }}
                  onSubmit={(values, { setSubmitting }) => {
                    this.requestNewPassword(values, setSubmitting);
                  }}
                  validationSchema={Yup.object().shape({
                    useremail: Yup.string()
                      .email(i18n.t('email.require.valid'))
                      .required(i18n.t('email.require.email'))
                  })}>
                  {({
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => {
                    return (
                      <form onSubmit={handleSubmit} className="form-signin">
                        <div className="form-group">
                          <label htmlFor="useremail">{i18n.t('email.text')}</label>
                          <input
                            id="useremail"
                            type="text"
                            className={`form-control ${touched.useremail && errors.useremail
                              ? 'is-invalid'
                              : ''
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage
                            component="div"
                            name="useremail"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          {this.state.reCaptchaSiteKey && <Reaptcha
                            ref={(reCaptcha) => { this.reCaptcha = reCaptcha }}
                            sitekey={this.state.reCaptchaSiteKey}
                            onLoad={this.resetCaptcha}
                            onVerify={this.setReCaptchaToken}
                            hl={i18n.language}
                          />}
                        </div>
                        <Button
                          id="request-password"
                          variant={'primary'}
                          block={true}
                          type="submit"
                          disabled={!this.state.reCaptchaToken || isSubmitting}
                        >
                          {i18n.t('password.request.password')}
                        </Button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RestorePasswordPage;
