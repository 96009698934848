import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import BackendServices from './BackendServices';
import * as serviceWorker from './serviceWorker';
import Log from 'js-logger';
import {createStore} from 'redux';
import reducer from './redux/Auth'
import {Provider} from 'react-redux';
import {extractContextPathPrefix, isDevelopmentEnv} from './util';
// https://getbootstrap.com/docs/4.0/getting-started/webpack/#importing-compiled-css
import 'bootstrap/dist/css/bootstrap.min.css';
// https://getbootstrap.com/docs/4.0/getting-started/webpack/#importing-javascript
import 'bootstrap';

let isDevelopment = false;

if (isDevelopmentEnv()) {
  isDevelopment = true;
}

Log.useDefaults();
Log.setLevel(isDevelopment ? Log.DEBUG : Log.WARN);

const store = createStore(reducer);

BackendServices.contextPathPrefix = extractContextPathPrefix();

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
