import React from 'react';
import {Modal, Button, Container, Form, Row, Spinner} from 'react-bootstrap';
import {Field, Formik} from 'formik';
import * as Yup from 'yup';
import BackendServices from '../../BackendServices';
import Reaptcha from 'reaptcha';
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class RequestDemoProject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reCaptchaSiteKey: null,
      reCaptchaToken: null,
      success: false
    };
  }

  componentDidMount() {
    if (!this.state.reCaptchaSiteKey) {
      BackendServices.getReCaptchaSiteKey().then(key => this.setState({reCaptchaSiteKey: key}));
    }
  }

  render() {
    const validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .max(32, 'Die Länge des Vornamens ist auf 32 Zeichen begrenzt')
        .required('Erforderlich'),
      lastName: Yup.string()
        .max(32, 'Die Länge des Nachnamens ist auf 32 Zeichen begrenzt')
        .required('Erforderlich'),
      company: Yup.string()
        .max(255, 'Die Länge des Firmennamens ist auf 255 Zeichen begrenzt')
        .required('Erforderlich'),
      email: Yup.string()
        .email('Bitte geben Sie eine gültige E-Mail Adresse ein')
        .required('Erforderlich'),
      phoneNumber: Yup.string()
        .max(32, 'Die Länge der Telefonnummer ist auf 32 Zeichen begrenzt')
        .required('Erforderlich')
    });
    return (
      <Container>
        <SuccessModal show={this.state.success}/>
        <ErrorModal show={this.state.isError}/>
        <Row className={'justify-content-center my-4'}><h1>Demozugang n.core</h1></Row>
        <Row className={'justify-content-center'}>
          <Formik
            validateOnChange={false}
            initialValues={{
              salutation: 'Herr',
              company: '',
            }}
            onSubmit={async (values, {setErrors, setSubmitting}) => {
              const response = await BackendServices.requestNewDemoProject(values, this.state.reCaptchaToken);
              if (response.ok) {
                this.setState({success: true});
              }else if (response.status === 400) {
                let errorCode = (await response.json()).errorCode;
                if (errorCode === 'DEMOPROJECT_WITH_EMAIL_ALREADY_EXISTS') {
                  setErrors({email: 'E-Mail-Adresse bereits für einen Demozugang verwendet.'});
                  setSubmitting(false);
                } else {
                  this.setState({isError : true})
                }
              }else{
                this.setState({isError : true})
              }

            }}
            validationSchema={validationSchema}
            render={({handleSubmit, errors, isSubmitting}) => (
              <Form onSubmit={handleSubmit}>
                <Field name='salutation' render={({field}) => (
                  <Form.Group>
                    <Form.Label>Ansprache</Form.Label>
                    <Form.Control as="select" name={'salutation'} onChange={field.onChange}>
                      <option value={'Herr'}>Herr</option>
                      <option value={'Frau'}>Frau</option>
                    </Form.Control>
                  </Form.Group>
                )}/>
                <Field name='firstName' render={({field}) => (
                  <Form.Group>
                    <Form.Label>Vorname</Form.Label>
                    <Form.Control type={'text'} name={'firstName'} onChange={field.onChange}
                      isInvalid={!!errors.firstName}/>
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>
                )}/>
                <Field name='lastName' render={({field}) => (
                  <Form.Group>
                    <Form.Label>Nachname</Form.Label>
                    <Form.Control type={'text'} name={'lastName'} onChange={field.onChange}
                      isInvalid={!!errors.lastName}/>
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                  </Form.Group>
                )}/>
                <Field name='company' render={({field}) => (
                  <Form.Group>
                    <Form.Label>Firma</Form.Label>
                    <Form.Control type={'text'} name={'company'} onChange={field.onChange}
                      isInvalid={!!errors.company}/>
                    <Form.Control.Feedback type="invalid">{errors.company}</Form.Control.Feedback>
                  </Form.Group>
                )}/>
                <Field name='phoneNumber' render={({field}) => (
                  <Form.Group>
                    <Form.Label >Telefon</Form.Label>
                    <Form.Control type={'text'} name={'phoneNumber'} onChange={field.onChange}
                      isInvalid={!!errors.phoneNumber}/>
                    <Form.Control.Feedback type="invalid">{errors.phoneNumber}</Form.Control.Feedback>
                  </Form.Group>
                )}/>
                <Field name='email' render={({field}) => (
                  <Form.Group className={'mb-5'}>
                    <Form.Label >E-Mail</Form.Label>
                    <Form.Control type={'text'} name={'email'} onChange={field.onChange}
                      isInvalid={!!errors.email}/>
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                )}/>
                {
                  this.state.reCaptchaSiteKey ? (<Reaptcha
                    ref={(reCaptcha) => { this.reCaptcha = reCaptcha}}
                    sitekey={this.state.reCaptchaSiteKey}
                    onLoad={() => this.setState({reCaptchaToken: null})}
                    onVerify={(token) => this.setState({reCaptchaToken: token})}
                    onExpire={() => this.setState({reCaptchaToken: null})}
                  />) : <Spinner animation={'border'}/>
                }
                <Form.Group>
                  <Button variant="primary" type="submit" className={'mt-3'} block={'true'}
                    disabled={!this.state.reCaptchaToken || isSubmitting}>
                                Demozugang anfordern
                  </Button>
                </Form.Group>
              </Form>
            )}
          />
        </Row>
      </Container>
    );
  }
}
function SuccessModal(props) {
  return (<Modal
    show={props.show}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
                Ihr Demozugang wird nun eingerichtet ...
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
                In wenigen Minuten werden Sie eine E-Mail erhalten, welche Informationen zu den nächsten
                Schritten enthält.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={ () => window.location = '/#'}>Ok</Button>
    </Modal.Footer>
  </Modal>);
}

function ErrorModal(props) {
  return (<Modal
    show={props.show}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        <FontAwesomeIcon icon={faExclamationTriangle} style={{color: 'red'}}/>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
          Bei der Einrichtung Ihres Demozugangs ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => { window.location.reload();}}>Ok</Button>
    </Modal.Footer>
  </Modal>);
}
